import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Countdown from "../components/countdown"
import "./index.css"

import Tunnel from "../assets/twa-tunnel__large.jpg"
import Us from "../assets/rng.jpg"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    {/* is main not doing anything? */}
    <div className="main ">
      <div className="border-bottom-wrapper">
        <div className="border-bottom">
          <div className="cover-grid">
            <div className="column column-border-wrapper big-cards">
              {/* TODO: figure out elegant right align and displace for ampersand */}
              <div className="column-border">
                <div className="column-cell">
                  <h1>You won't want to miss this</h1>
                  <Link className="button-link" to="/rsvp">
                    RSVP
                  </Link>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="column-cell">
                <div className="image-container">
                  <img src={Us}></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-bottom-wrapper">
        <div className="border-bottom">
          <div className="bottom-grid">
            <div className="column column-border-wrapper">
              <div className="column-border">
                <div className="column-cell">
                  <Countdown />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="column-cell ">
                <h2>STAY WITH US</h2>
                <Link className="button-link__roundstyle" to="/hotel">
                  ROOM BLOCK
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-bottom-wrapper">
        <div className="border-bottom">
          <div className="full-width">
            <img src={Tunnel} />
          </div>
        </div>
      </div>
      <div className="border-bottom-wrapper">
        <div className="border-bottom">
          <div className="top-grid">
            <div className="column column-border-wrapper">
              <div className="column-border">
                <div className="column-cell">
                  <div className="big-mobile">
                    <div className="big-card">
                      <h1>R</h1>
                      <h1>&</h1>
                      <h1>G</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column-highlight-wrapper">
              <div className="border-bottom-wrapper">
                <div className="border-bottom">
                  <div className="row-grid">
                    <div className="row">
                      <div className="row-cell">
                        <h2>Our Itinerary</h2>
                        <Link className="button-link" to="/schedule">
                          PARTY
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-grid">
                <div className="row">
                  <div className="row-cell">
                    <h2>Dress Code:</h2>
                    <h4>
                      <em>elevated</em> cocktail attire
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-bottom-wrapper">
        <div className="border-bottom">
          <div className="full-width">
            <div
              className="oval-card-container"
              style={{ height: "200px", marginTop: "-8px" }}
            >
              <div className="oval-content">
                <h3>
                  #RXG
                  <span style={{ fontSize: "36px", fontStyle: "italic" }}>
                    VIA
                  </span>
                  <span style={{ fontSize: "18px" }}> </span>TWA
                </h3>
              </div>
              <div
                className="oval-card"
                style={{ height: "105%", width: "105%", margin: "-65px -25px" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
